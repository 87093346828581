const districtByIso = {
  'RU-BEL': 'cfo',
  'RU-BRY': 'cfo',
  'RU-VLA': 'cfo',
  'RU-VOR': 'cfo',
  'RU-IVA': 'cfo',
  'RU-KLU': 'cfo',
  'RU-KOS': 'cfo',
  'RU-KRS': 'cfo',
  'RU-LIP': 'cfo',
  'RU-MOS': 'cfo',
  'RU-MOW': 'cfo',
  'RU-ORL': 'cfo',
  'RU-RYA': 'cfo',
  'RU-SMO': 'cfo',
  'RU-TAM': 'cfo',
  'RU-TVE': 'cfo',
  'RU-TUL': 'cfo',
  'RU-YAR': 'cfo',
  'RU-ARK': 'szfo',
  'RU-VLG': 'szfo',
  'RU-KGD': 'szfo',
  'RU-KR': 'szfo',
  'RU-KO': 'szfo',
  'RU-LEN': 'szfo',
  'RU-MUR': 'szfo',
  'RU-NEN': 'szfo',
  'RU-NGR': 'szfo',
  'RU-PSK': 'szfo',
  'RU-SPE': 'szfo',
  'RU-AD': 'yfo',
  'RU-AST': 'yfo',
  'RU-VGG': 'yfo',
  'RU-KL': 'yfo',
  'RU-KDA': 'yfo',
  'RU-SEV': 'yfo',
  'RU-KRY': 'yfo',
  'RU-ROS': 'yfo',
  'RU-DA': 'skfo',
  'RU-IN': 'skfo',
  'RU-KB': 'skfo',
  'RU-KC': 'skfo',
  'RU-SE': 'skfo',
  'RU-STA': 'skfo',
  'RU-CE': 'skfo',
  'RU-BA': 'pfo',
  'RU-KIR': 'pfo',
  'RU-ME': 'pfo',
  'RU-MO': 'pfo',
  'RU-NIZ': 'pfo',
  'RU-ORE': 'pfo',
  'RU-PNZ': 'pfo',
  'RU-PER': 'pfo',
  'RU-SAM': 'pfo',
  'RU-SAR': 'pfo',
  'RU-TA': 'pfo',
  'RU-UD': 'pfo',
  'RU-ULY': 'pfo',
  'RU-CU': 'pfo',
  'RU-KGN': 'urfo',
  'RU-SVE': 'urfo',
  'RU-TYU': 'urfo',
  'RU-KHM': 'urfo',
  'RU-CHE': 'urfo',
  'RU-YAN': 'urfo',
  'RU-ALT': 'sfo',
  'RU-AL': 'sfo',
  'RU-BU': 'sfo',
  'RU-ZAB': 'sfo',
  'RU-IRK': 'sfo',
  'RU-KEM': 'sfo',
  'RU-KYA': 'sfo',
  'RU-NVS': 'sfo',
  'RU-OMS': 'sfo',
  'RU-TOM': 'sfo',
  'RU-TY': 'sfo',
  'RU-KK': 'sfo',
  'RU-AMU': 'dfo',
  'RU-YEV': 'dfo',
  'RU-KAM': 'dfo',
  'RU-MAG': 'dfo',
  'RU-PRI': 'dfo',
  'RU-SA': 'dfo',
  'RU-SAK': 'dfo',
  'RU-KHA': 'dfo',
  'RU-CHU': 'dfo',
};

export default districtByIso;
